import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, BaseComponent } from '@nstep-common/core';
import { ModalComponent } from '@nstep-common/semantic-ui';
import { toast } from '@nstep-common/utils';
import { OnlineTransactionActionModel, AggregateTransactions, AllowedDto, EntitlementTransactionDtoModel, ExtendedPOSLocationDto, PurchaseBalanceDto } from '@nstep-public/pages';
import { AmisCardService, CancelTransactionComponent, OnlineTransactionService, OperatorService } from '@nstep-public/shared';
import { chain, flatten } from 'lodash';
import * as CryptoJS from 'crypto-js';

@Component({
	selector: 'app-online-transactions',
	templateUrl: './online-transactions.component.html'
})
export class OnlineTransactionsComponent extends BaseComponent implements OnInit {

	@ViewChild(CancelTransactionComponent) cancelTransactionModal!: CancelTransactionComponent;
	@ViewChild('accessDeniedModal') accessDeniedModal!: ModalComponent;
	currentDate = new Date();
	currentPosLocationInfo!: ExtendedPOSLocationDto;

	tabs = ['searchCard', 'nonRationedTypes', 'rationedTypes', 'confirmTransaction', 'insertPIN'];
	currentTab = this.tabs[0];
	purchaseBalanceData!: PurchaseBalanceDto;
	nonRationedItems: AllowedDto[] = [];

	rationedItems: AllowedDto[] = [];
	rationedItemsBackup: AllowedDto[] = [];

	attempts = 0;

	loading = false;

	sentTransaction = false;
	transactionDate = '';

	constructor(private authService: AuthService,
		private router: Router,
		private onlineTransactionService: OnlineTransactionService,
		private amisCardService: AmisCardService,
		private operatorService: OperatorService) {
		super();

		if (!this.operatorService.getLocationInfo()) {
			this.router.navigate(['select-location']);
		} else {
			this.currentPosLocationInfo = JSON.parse(this.operatorService.getLocationInfo());
		}
	}

	ngOnInit(): void {
		setInterval(() => {
			this.currentDate = new Date();
		}, 1000);
	}

	search(amisCardNumber: string): void {
		this.loading = true;

		this.subscriptions.push(
			this.onlineTransactionService.getPurchaseBalance(amisCardNumber, this.currentPosLocationInfo.location.id).subscribe({
				next: (response: PurchaseBalanceDto) => {
					this.purchaseBalanceData = response;

					this.sentTransaction = false;
					this.transactionDate = new Date().toISOString();

					this.purchaseBalanceData.allowed.forEach(item => {
						if (!item.rationed) {
							item.available = true;
							this.nonRationedItems.push(item);
						} else {
							item.parsedBalance = parseInt(item.balance);
							item.transactionAmount = 0;
							this.rationedItems.push(item);
						}
					});

					this.purchaseBalanceData.forbidden.forEach(item => {
						if (!item.rationed) {
							item.available = true;
							this.nonRationedItems.push({ rationed: item.rationed, entitlementType: item.entitlementType, available: item.available } as AllowedDto);
						}
					});

					this.nonRationedItems = chain(this.nonRationedItems)
						.orderBy(d => d.entitlementType)
						.value();

					this.rationedItems = chain(this.rationedItems)
						.orderBy(d => d.entitlementType)
						.value();

					this.loading = false;
					this.currentTab = this.tabs[1];
					this.attempts = 1;
				},
				error: (response) => {

					this.loading = false;

					const errors: string[] = flatten(Object.values(response));

					errors.forEach(error => {
						toast('', error, 'red');
					});
				}
			})
		);
	}

	onAction(model: OnlineTransactionActionModel): void {

		if (model.action === 'cancel') {
			if (model.currentTabIndex === 1) {
				this.cancelTransaction();
			} else if (model.currentTabIndex === 2) {
				this.cancelTransactionModal.toggle();
			} else if (model.currentTabIndex === 3) {
				this.rationedItems = JSON.parse(JSON.stringify(this.rationedItemsBackup));
				this.currentTab = this.tabs[model.currentTabIndex - 1];
			} else {
				this.currentTab = this.tabs[model.currentTabIndex - 1];
			}
		} else {
			if (model.currentTabIndex === 2) {
				this.rationedItemsBackup = JSON.parse(JSON.stringify(this.rationedItems));
				this.rationedItems = this.rationedItems.filter(e => e.transactionAmount > 0);
				this.currentTab = this.tabs[model.currentTabIndex + 1];
			} else if (model.currentTabIndex === 4) {
				this.makeTransactions(model.pin!)
			} else {
				this.currentTab = this.tabs[model.currentTabIndex + 1];
			}
		}
	}

	cancelTransaction(): void {
		this.rationedItems = [];
		this.nonRationedItems = [];
		this.amisCardService.changeAmisCardNumber('');
		this.currentTab = this.tabs[0];
		this.sentTransaction = false;
	}

	makeTransactions(pin: string): void {
		if (this.sentTransaction) {
			return;
		}

		this.sentTransaction = true;
		this.loading = true;

		const dataToSave = chain(this.rationedItems)
			.map(e => new EntitlementTransactionDtoModel({
				amiscardId: e.amisCardId,
				entitlementCodeId: e.entitlementCodeId,
				entitlementTypeId: e.entitlementTypeId,
				measurementUnitAssociationId: e.measurementUnitId,
				transactionAmount: e.transactionAmount,
				deviceTransactionId: crypto.randomUUID(),
				timeStamp: this.transactionDate
			}))
			.value();

		const purchaseModel: AggregateTransactions = {
			transactions: dataToSave
		};

		const hashedPINStep1 = CryptoJS.SHA1(pin);
		const hashedPINStep2 = CryptoJS.MD5(hashedPINStep1.toString(CryptoJS.enc.Hex));
		const hashedPINStep3 = hashedPINStep2.toString(CryptoJS.enc.Hex);

		this.subscriptions.push(
			this.onlineTransactionService.createPurchaseTransactions(this.currentPosLocationInfo.location.id, this.purchaseBalanceData.card.cardNumber, hashedPINStep3, purchaseModel).subscribe({
				next: () => {
					toast('Success', 'The transaction was successfully completed!', 'green');
					this.cancelTransaction();
					this.onlineTransactionService.clearOnlineTransactionsCache();
					this.loading = false;
				},
				error: (response: any) => {

					const errors: string[] = flatten(Object.values(response));

					if (errors.some(d => d === 'The PIN is incorrect')) {
						toast('Error', 'The PIN is incorrect.', 'red');

						this.attempts += 1;
						this.onlineTransactionService.changePin('');
						this.sentTransaction = false;
					} else if (errors.some(d => d.startsWith('Access to '))) {
						this.accessDeniedModal.toggle();
					} else {
						toast('Error', errors[0], 'red');
					}

					if (this.attempts > 3) {
						this.cancelTransaction();
					}

					this.loading = false;
				}
			})
		);
	}

	logout(): void {
		this.accessDeniedModal.toggle();
		this.authService.logOut(true);
	}
}
