<ng-container *ngIf="!userIsAuthenticated">
	<app-authentication [userNamePlaceHolder]="userNamePlaceholder"
		[passwordPlaceHolder]="passwordPlaceholder"
		[userNameMaxLength]="userNameMaxLength"
		[passwordMaxLength]="passwordMaxLength"
		[requires2F]="requires2F"
		[validation]="validation"
		[errors]="errors"
		[loading]="loading"
		(validateEvent)="validate($event)"
		(submitEvent)="onSubmit($event)">
	</app-authentication>
</ng-container>

<sm-modal #clientConsentModal [isTiny]="true" (closeEvent)="consentClient(false); clientConsentModal.toggle();">
	<ng-container header>Authorize External Client</ng-container>

	<ng-container content>
		<h4 class="ui header">
			<i class="user shield icon"></i>
			<div class="content">

				<div class="sub header">
					<p>Do you want to give "{{verifiedClient.name}}" access to your data?</p>
				</div>
			</div>
		</h4>
	</ng-container>

	<ng-container actions>
		<button class="ui red right labeled icon button"
			(click)="consentClient(false); clientConsentModal.toggle();">
			Deny
			<i class="cancel icon"></i>
		</button>
		<button class="ui green right labeled icon button"
			(click)="consentClient(true); clientConsentModal.toggle();">
			Allow
			<i class="check icon"></i>
		</button>
	</ng-container>
</sm-modal>