import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, BaseComponent } from '@nstep-common/core';
import { DropdownOption } from '@nstep-common/semantic-ui';
import { toast } from '@nstep-common/utils';
import { ExtendedPOSLocationDto } from '@nstep-public/pages';
import { OperatorService } from '@nstep-public/shared';
import { chain } from 'lodash';

@Component({
	selector: 'app-select-location',
	templateUrl: './select-location.component.html'
})
export class SelectLocationComponent extends BaseComponent implements OnInit {

	loading = false;
	allLocationsDisabled = false;

	posLocationsData!: ExtendedPOSLocationDto[];

	posLocationsDropdownValues: DropdownOption[] = [];
	selectedPosLocationId: number | null = null;
	selectedPosLocation: ExtendedPOSLocationDto | null = null;

	constructor(private operatorService: OperatorService,
		private authService: AuthService,
		private router: Router) {
		super();
	}

	ngOnInit(): void {
		if (this.authService.JWT?.role != 'Operator') {
			this.router.navigate(['']);
		}

		this.loading = true;

		this.subscriptions.push(
			this.operatorService.getLocations().subscribe({
				next: (response: ExtendedPOSLocationDto[]) => {

					if (!response.length) {
						this.authService.logOut(true);
						toast('', 'There are no POS Locations attached to your account active at this hour. You have been automatically logged out.', 'orange');
					}

					this.posLocationsData = response;

					this.allLocationsDisabled = this.posLocationsData.every(d => !d.location.enabled);

					let selectionAlreadyMade = false;

					this.posLocationsDropdownValues = chain(this.posLocationsData)
						.map(item => {

							if (item.location.enabled && !selectionAlreadyMade) {

								selectionAlreadyMade = true;
								this.selectedPosLocationId = item.location.id;

								return new DropdownOption({
									name: item.location.name,
									value: item.location.id,
									disabled: false
								});

							} else {

								return new DropdownOption({
									name: item.location.name,
									value: item.location.id,
									disabled: !item.location.enabled
								});
							}
						})
						.orderBy(item => item.name)
						.value();

					if (this.operatorService.getLocationInfo()) {
						const currentPosLocationSelection: ExtendedPOSLocationDto = JSON.parse(this.operatorService.getLocationInfo());

						const disabledItem = this.posLocationsDropdownValues.find(d => d.value === currentPosLocationSelection.location.id)!.disabled;

						this.selectedPosLocationId = !disabledItem ? currentPosLocationSelection.location.id : null;
					}

					this.selectedPosLocation = this.selectedPosLocationId ? this.posLocationsData.find(d => d.location.id === this.selectedPosLocationId)! : null;

					this.loading = false;

				},
				error: () => {
					this.loading = false;
				}
			})
		);
	}

	submit(): void {
		this.operatorService.setLocationInfo(this.selectedPosLocation!);
		this.router.navigate(['online-transactions']);
	}

	onPosLocationValueChange(): void {
		this.selectedPosLocation = this.posLocationsData.find(d => d.location.id === this.selectedPosLocationId)!;
	}
}
