<h2 class="ui header">Transaction History</h2>

<div class="ui form">
	<div class="three fields">
		<div class="field">
			<label>Card Number:</label>
			<sm-dropdown class="ui fluid selection dropdown label"
				[class.loading]="!cardsDropdownValues.length"
				[(value)]="selectedCardNumber"
				[options]="cardsDropdownValues"
				(valueChange)="onCardValueChange()">
				<div class="text"></div>
				<i class="dropdown icon"></i>
			</sm-dropdown>
		</div>

		<div class="field">
			<label>Entitlement Type:</label>
			<sm-dropdown class="ui fluid selection dropdown label"
				[class.loading]="!cardEntitlementTypesDropdownValues.length"
				[(value)]="selectedEntitlementType"
				[options]="cardEntitlementTypesDropdownValues"
				(valueChange)="onEntitlementTypeValueChange()">
				<div class="text"></div>
				<i class="dropdown icon"></i>
			</sm-dropdown>
		</div>
	</div>

	<div class="field">
		<app-table-view
			[itemTemplate]="transactionHistoryActionCell"
			[data]="tableData"
			[noSearch]="true"
			[dataReady]="tableDataReady"
			[columns]="tableColumns">
		</app-table-view>
	</div>
</div>

<ng-template #transactionHistoryActionCell let-item="item">
	<tr style="text-align: center;">
		<td>{{ item.cardNumber }}</td>
		<td>{{ item.headquarter }}</td>
		<td>
			<span class="ui text" [ngClass]="item.transactionAmount > 0 ? 'success' : 'error'">
				<strong>{{ item.transactionAmount }}</strong>
			</span>
		</td>
		<td>
			<div class="ui tag label">
				<i class="icon" style="margin: 0 0.25rem 0 0; font-size: 1.3em; vertical-align: middle;" [ngClass]="{ 
					'fill drip': item.measurementUnit === MeasurementUnit.Liters, 
					'cubes': item.measurementUnit === MeasurementUnit.Units, 
					'wine bottle': item.measurementUnit === MeasurementUnit.Bottles, 
					'shapes': item.measurementUnit === MeasurementUnit.Cans,
					'weight': item.measurementUnit === MeasurementUnit.Grams,
					'euro sign': item.measurementUnit === MeasurementUnit.Euros,
					'syringe': item.measurementUnit === MeasurementUnit.Injections,
				}"></i>
				{{ item.measurementUnit }}
			</div>
		</td>
		<td>{{ item.timeStamp }}</td>
		<td>{{ item.posLocation | dash }}</td>
		<td>
			<div class="ui label" [ngClass]="{
				'green': item.transactionStatus === TransactionState.Active,
				'yellow': item.transactionStatus === TransactionState.Transacted,
				'bright-red': item.transactionStatus === TransactionState.Expired,
				'black': item.transactionStatus === TransactionState.Closed,
				'light-blue': item.transactionStatus === TransactionState.PaperCard,
			}">
				{{ item.transactionStatus }}
			</div>
		</td>
		<td>
			<div class="ui label" [ngClass]="{ 
				'primary': item.transactionType === TransactionType.InitialRations, 
				'orange': item.transactionType === TransactionType.WeeklyService, 
				'light-purple': item.transactionType === TransactionType.POSDevice, 
				'blue': item.transactionType === TransactionType.AdminAdjustment,
				'teal': item.transactionType === TransactionType.PaperCard,
				'violet': item.transactionType === TransactionType.WebOperator,
				'purple': item.transactionType === TransactionType.WebOperatorBulk,
				'brown': item.transactionType === TransactionType.TotalApplication
			}">
				{{ item.transactionType }}
			</div>
		</td>
		<td>{{ item.startDate | dash }}</td>
		<td>{{ item.expirationDate | dash }}</td>
		<td *ngIf="item.notes && item.notes.length > notesMaximumDisplayLength; else fullNotes">
			<div style="cursor: pointer; color: #4183c4"
				[attr.data-tooltip]="item.notesFullyDisplayed ? 'Click to collapse' : 'Click to expand'"
				(click)="toggleNotes(item)">
				{{ item.displayNotes }}
			</div>
		</td>
		<ng-template #fullNotes>
			<td>{{ item.displayNotes | dash }}</td>
		</ng-template>
	</tr>
</ng-template>