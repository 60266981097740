import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ValidationErrors } from 'fluentvalidation-ts';
import { flatten } from 'lodash';
import { EMPTY, catchError, finalize, tap } from 'rxjs';

import { AuthService, AuthenticationModel, BaseComponent } from '@nstep-common/core';
import { AppSource } from '@nstep-common/utils';
import { LoginModelValidator, LoginOperatorModelValidator } from '@nstep-public/pages';
import { ClientsService } from '@nstep-public/shared';
import { ModalComponent } from '@nstep-common/semantic-ui';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html'
})
export class LoginComponent extends BaseComponent implements OnInit {
	@ViewChild('clientConsentModal') clientConsentModal!: ModalComponent;
	verifiedClient: any = {};

	validation: ValidationErrors<AuthenticationModel> = {};
	errors: string[] = [];
	loading: boolean = false;

	isOperatorRoute = false;

	userNamePlaceholder = '';
	userNameMaxLength: number | null = null;

	passwordPlaceholder = '';
	passwordMaxLength: number | null = null;

	requires2F: boolean = false;
	userIsAuthenticated = false;

	constructor(private authService: AuthService,
		private clientService: ClientsService,
		private router: Router,
		private route: ActivatedRoute) {
		super();
	}

	ngOnInit(): void {
		if (this.authService.JWT) {
			this.userIsAuthenticated = true;

			if (!this.verifyClient()) {
				this.router.navigate(['']);
			}
		}

		if (this.router.url.endsWith('operator')) {
			this.isOperatorRoute = true;
			this.userNamePlaceholder = 'User Name';
			this.passwordPlaceholder = 'Password';
			this.userNameMaxLength = null;
			this.passwordMaxLength = null;
		}
		else {
			this.isOperatorRoute = false;
			this.userNamePlaceholder = 'AMIS CARD NUMBER';
			this.passwordPlaceholder = 'PIN';
			this.userNameMaxLength = 14;
			this.passwordMaxLength = 4;
		}
	}

	validate(value: AuthenticationModel): void {
		const validator = this.isOperatorRoute ? new LoginOperatorModelValidator() : new LoginModelValidator();
		this.validation = validator.validate(value);
	}

	onSubmit(value: AuthenticationModel): void {
		this.validate(value);

		if (Object.keys(this.validation).length != 0) {
			return;
		}

		this.loading = true;

		const logIn = this.authService
			.logIn(value)
			.pipe(
				tap(jwt => {
					this.requires2F = jwt.requiresTwoFactor;

					if (jwt.requiresPasswordReset) {
						this.router.navigate(['change-password'], {
							state: {
								userName: value.userName,
								appSource: AppSource.Public
							}
						});
					} else {
						if (this.isOperatorRoute && jwt.token.role == 'Operator') {
							this.router.navigate(['select-location']);
						}
						else if (!this.verifyClient()) {
							this.router.navigate(['']);
						}
					}
				}),
				catchError((resp: any) => {
					const errors = flatten(Object.values(resp)) as string[];

					this.errors = errors;

					return EMPTY;
				}),
				finalize(() => this.loading = false)
			)
			.subscribe();

		this.subscriptions.push(logIn);
	}

	verifyClient(): boolean {
		const clientId = this.route.snapshot.queryParamMap.get('clientId') || null;
		if (!clientId) {
			return false;
		}

		this.loading = true;

		const verifyClient = this.clientService.verifyClient(clientId)
			.pipe(
				tap(resp => {
					if (resp) {
						this.verifiedClient = resp;
						this.clientConsentModal.toggle();
					}
					else {
						this.router.navigate(['']);
					}
				}),
				catchError(() => {
					this.router.navigate(['']);
					return EMPTY;
				}),
				finalize(() => this.loading = false)
			)
			.subscribe();

		this.subscriptions.push(verifyClient);

		return true;
	}

	consentClient(yes: boolean): void {
		if (!yes) {
			this.router.navigate(['']);
			return;
		}

		this.loading = true;

		const authorizeClient = this.clientService.authorizeClient(this.verifiedClient.id)
			.pipe(
				tap(resp => {
					window.location.href = resp.redirectUrl;
				}),
				catchError(() => {
					this.router.navigate(['']);
					return EMPTY;
				}),
				finalize(() => this.loading = false)
			)
			.subscribe();

		this.subscriptions.push(authorizeClient);
	}
}
