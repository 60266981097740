import { JsonProperty } from '@nstep-common/utils';

export class EntitlementCheckModel {
	amisCardNumber!: string;
}

export class CodeShortDto {
	@JsonProperty({ type: Number })
	id!: number;

	@JsonProperty({ type: Number })
	majorCode!: number;

	@JsonProperty({ type: Number })
	minorCode!: number;

	@JsonProperty({ type: String, nullable: true })
	group!: string | null;

	@JsonProperty({ type: String })
	description!: string;

	@JsonProperty({ type: Boolean })
	isDependent!: boolean;
}

export class EntitleShortDto {
	@JsonProperty({ type: Number, nullable: true })
	headquarterId!: number | null;

	@JsonProperty({ type: Number, array: true })
	entitlements!: number[];

	@JsonProperty({ type: CodeShortDto })
	code!: CodeShortDto;
}

export class HeadquarterShortDto {
	@JsonProperty({ type: Number })
	id!: number;

	@JsonProperty({ type: String })
	name!: string;

	@JsonProperty({ type: Number, array: true })
	types!: number[];
}

export class LocationShortDto {
	@JsonProperty({ type: Number })
	id!: number;

	@JsonProperty({ type: String })
	name!: string;

	@JsonProperty({ type: HeadquarterShortDto, array: true })
	hQs!: HeadquarterShortDto[];
}

export class UnrationedBalanceModel {
	@JsonProperty({ type: String })
	cardNumber!: string;

	@JsonProperty({ type: EntitleShortDto, array: true })
	cardEntitlements!: EntitleShortDto[];

	@JsonProperty({ type: LocationShortDto, array: true })
	posLocation!: LocationShortDto[];
}

export class HeadquarterDto {
	id!: number;
	name!: string;
}

export class EntitlementTypeDto {
	@JsonProperty({ type: Number })
	id!: number;

	@JsonProperty({ type: String })
	name!: string;

	@JsonProperty({ type: Number })
	rationingTypeId!: number;

	@JsonProperty({ type: Boolean })
	disabled!: boolean;

	@JsonProperty({ type: Number })
	minBuyAmount!: number;
}

export class ProccessedEntitlementTypes {
	fullCode!: string;
	posLocationName!: string;
	posLocationId!: number;
	hqName!: string;
	hqId!: number;
	entitlementTypes: EntitlementTypeSegment[] = [];

	constructor(data?: Partial<ProccessedEntitlementTypes> | null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class EntitlementTypeSegment {
	id!: number;
	available!: boolean;
	name!: string;

	constructor(data?: Partial<EntitlementTypeSegment> | null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}