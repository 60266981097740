import { Routes } from '@angular/router';
import { authGuard, ChangePasswordComponent } from '@nstep-common/core';
import { LayoutComponent, routePermissions } from '@nstep-public/shared';

import {
	AddBalanceComponent,
	BalanceComponent,
	EntitlementCheckComponent,
	LoginComponent,
	OnlineTransactionsComponent,
	ReportsComponent,
	SelectLocationComponent,
	TransactionHistoryComponent
} from '@nstep-public/pages';

export const routes: Routes = [
	{
		path: 'login',
		component: LoginComponent,
	},
	{
		path: 'login/operator',
		component: LoginComponent,
	},
	{
		path: 'change-password',
		component: ChangePasswordComponent
	},
	{
		path: 'select-location',
		canActivate: [authGuard(routePermissions)],
		component: SelectLocationComponent,
	},
	{
		path: '',
		component: LayoutComponent,
		canActivate: [authGuard(routePermissions)],
		children: [
			{
				path: 'add-balance',
				canActivate: [authGuard(routePermissions)],
				component: AddBalanceComponent,
			},
			{
				path: 'balance',
				canActivate: [authGuard(routePermissions)],
				component: BalanceComponent,
			},
			{
				path: 'entitlement-check',
				canActivate: [authGuard(routePermissions)],
				component: EntitlementCheckComponent
			},
			{
				path: 'online-transactions',
				canActivate: [authGuard(routePermissions)],
				component: OnlineTransactionsComponent,
			},
			{
				path: 'reports',
				canActivate: [authGuard(routePermissions)],
				component: ReportsComponent
			},
			{
				path: 'transaction-history',
				canActivate: [authGuard(routePermissions)],
				component: TransactionHistoryComponent,
			},
			{
				path: '**',
				redirectTo: ''
			}

		]
	}
];